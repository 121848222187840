import React, { useRef, useState } from "react";
import { Container } from "../components/Container";
import Slider from "react-slick";
import { page_data } from "../utils/utils";
import Video from "../utils/Video/Video";
import { image } from "../utils/images";
import Button from "../components/Buttons/Button";

const Section4 = () => {
  let { section4 } = page_data;
  const slideTO = useRef();
  const [state, setState] = useState(0);
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 10000,
    swipeToSlide: true,
    arrows: false,
    // cssEase: ""
    afterChange: (a) => setState(a),
  };
  return (
    <>
      <div className="section4">
        <Container>
          <div className="inner_section4">
            <Slider ref={slideTO} {...settings}>
              {section4.data.map((el, i) => (
                <div key={i} className="section4_box">
                  <div className="make_gap_secttion4">
                    <div className="secton4_left">
                      <div className="section4_left_title">{el.title}</div>
                      <div className="section4_left_desc">{el.desc}</div>
                      <Button custclass="section4_btn" txt={el?.btn} />
                    </div>

                    <div className="section4_box_img">
                      <div className="top_leftbox">{image.topleft}</div>
                      {/* <Video
                        custclass="section4_video"
                        data={el.video_link}
                        img_placeholder={el.img}
                      /> */}
                      <div
                        className="cust-video-iframe"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {/* <iframe
                          // width="560"
                          // height="315"
                          src={el.video_link}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        ></iframe> */}
                        <img
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                          src={el.img_link}
                        />
                      </div>
                      <div className="bottom_rightbox">{image.bottomright}</div>
                    </div>
                    <div className="secton4_left_mobile">
                      {/* <div className="section4_left_title">{el.title}</div> */}
                      <div className="section4_left_desc_mob">{el.desc}</div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="dots_collection">
              {section4.data.map((el, i) => (
                <div
                  key={i}
                  className={"dots " + (state === i && " activedots")}
                  onClick={() => {
                    setState(i);
                    slideTO.current.slickGoTo(i);
                  }}
                ></div>
              ))}
            </div>
            <div className="section4_btn_mob">
              <Button txt={section4.data[0].btn} />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Section4;
