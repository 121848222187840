import React from "react";

export const BookACall = ({ custClass, txt, click }) => {
  return (
    <div
      className={"book-a-call " + (custClass && ` ${custClass}`)}
      onClick={() => {
        if (click) {
          return click();
        }
        return;
      }}
    >
      {txt ? txt : "Book a call"}
    </div>
  );
};
