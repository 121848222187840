import React, { useRef, useState } from "react";
import { Container } from "../components/Container";
import { page_data } from "../utils/utils";
import Button from "../components/Buttons/Button";
import Slider from "react-slick";

const Section9 = () => {
  let { section9 } = page_data;
  const slideTo9 = useRef();
  const [state, setState] = useState(0);
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 10000,
    swipeToSlide: true,
    arrows: false,
    // cssEase: ""
    afterChange: (a) => setState(a),
  };
  return (
    <>
      <div className="section9">
        <Container>
          <div className="inner_section9">
            <div className="section9_title">
              <img src="https://imgur.com/FfYX4s7.jpg" />
              {section9.title}
            </div>
            <div className="section9_title_sub">{section9.sub}</div>
            <div className="section9_items">
              {section9.items.map((el, i) => (
                <div className="section9_box" key={i}>
                  <div className="section9_box_tick">{el.tick}</div>
                  <div className="section9_title_desc">
                    <div className="section9_box_title">{el.title}</div>
                    <div className="section9_box_desc">{el.desc}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
        <div className="section9_items_mobile">
          <img src="https://imgur.com/FfYX4s7.jpg" className="hat" />

          <Slider ref={slideTo9} {...settings}>
            {section9.items.map((el, i) => (
              <Container  key={i}>
                <div className="section9_box_mobile">
                  <div className="section9_box_tick_mobile">{el.tick}</div>
                  <div className="section9_title_desc_mobile">
                    <div className="section9_box_title_mobile">{el.title}</div>
                    <div className="section9_box_desc_mobile">{el.desc}</div>
                  </div>
                </div>
              </Container>
            ))}
          </Slider>
          <div className="dots_collection">
            {section9.items.map((el, i) => (
              <div
                key={i}
                className={"dots " + (state === i && " activedots")}
                onClick={() => {
                  setState(i);
                  slideTo9.current.slickGoTo(i);
                }}
              ></div>
            ))}
          </div>
        </div>
        <Button custclass="section9_btn" txt={section9?.btn} />
      </div>
    </>
  );
};

export default Section9;
