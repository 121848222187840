import React, { useRef, useState } from "react";
import { Container } from "../components/Container";
import { page_data } from "../utils/utils";
import Slider from "react-slick";
import { image } from "../utils/images";

const Section3 = () => {
  const slideTO1 = useRef();
  const [state, setState] = useState(0);

  let { section3 } = page_data;
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 10000,
    swipeToSlide: true,
    arrows: false,
    // cssEase: ""
    afterChange: (a) => setState(a),
  };
  return (
    <>
      <div className="section3">
        <Container>
          <div className="setion3_title">{section3.title}</div>
          <div className="comments">
            {/* <img className="star_img" src="https://imgur.com/Pg8ItLk.jpg"></img> */}
            <div className="star_img">
            {image.star}
            </div>
            <Slider ref={slideTO1} {...settings}>
              {section3.data.map((el, i) => (
                <div key={i} className="inner_commnet">
                  <div className="making_gap">
                    <div className="inner_left">
                      <div className="client_detail">
                        <img src={el.img}></img>
                        <div className="client_name">
                          <div className="clientdesc_1">{el.name}</div>
                          <div className="clientdesc_2">{el.profession}</div>
                          <div className="clientdesc_3">{el.country}</div>
                        </div>
                      </div>
                      <div className="desc">{el.desc}</div>
                      <div className="solving_status">{el.problem_solve}</div>
                    </div>
                    <div className="inner_right">{el.projectimg}</div>
                  </div>
                </div>
              ))}
            </Slider>
            
            <div className="dots_collection">
              {section3.data.map((el, i) => (
                <div
                  key={i}
                  className={"dots " + (state === i && " activedots")}
                  onClick={() => {
                    setState(i);
                    slideTO1.current.slickGoTo(i);
                  }}
                ></div>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Section3;
