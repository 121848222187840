import { image } from "./images";

const section2 = {
  title: "Our golden snitches",
  exp: {
    count: <div>05+</div>,
    desc: "Years of experience",
  },
  data: [
    {
      count: "200+",
      desc: "Happy clients",
    },
    {
      count: "10,000+",
      desc: "Hours saved",
    },
    {
      count: "20+",
      desc: "Industries served",
    },
    {
      count: "100%",
      desc: "Client retention",
    },
  ],
  brand: [
    {
      img: "https://imgur.com/7xLSAlG.jpg",
      link: "",
    },
    {
      img: "https://framerusercontent.com/images/xtljcmlB8oXXrn96bwFgxicviMY.svg",
      link: "",
    },
    {
      img: "https://framerusercontent.com/images/ygVXMv4mljYhaCH3cbn5vMsxiGs.svg",
      link: "",
    },
    {
      img: "https://imgur.com/EDwbKst.jpg",
      link: "",
    },
    {
      img: "https://imgur.com/rdtG0Uo.jpg",
      link: "",
    },
    {
      img: "https://imgur.com/PLnmFoe.jpg",
      link: "",
    },
    {
      img: "https://imgur.com/eBSd3vG.jpg",
      link: "",
    },
    {
      img: "https://imgur.com/eKkDPQw.jpg",
      link: "",
    },
    {
      img: "https://imgur.com/Xm6lFm5.jpg",
      link: "",
    },
    {
      img: "https://imgur.com/J7EYt3W.jpg",
      link: "",
    },
    {
      img: "https://imgur.com/JyJcqcI.jpg",
      link: "",
    },
    {
      img: "https://imgur.com/VkPHqnQ.jpg",
      link: "",
    },
    {
      img: "https://imgur.com/PG7fJPa.jpg",
      link: "",
    },
    {
      img: "https://imgur.com/VrjwWMG.jpg",
      link: "",
    },
    {
      img: "https://imgur.com/7z7UfE5.jpg",
      link: "",
    },
    {
      img: "https://imgur.com/1S3wulo.jpg",
      link: "",
    },
    {
      img: "https://imgur.com/IDTE3Jg.jpg",
      link: "",
    },
  ],
  btn: "Add me to the list",
};

const section3 = {
  title: (
    <>
      <div className="section3_title_desktop">
        Witness undeniable social proof?
      </div>
      <div className="section3_title_mobile">Undeniable social proof?</div>
    </>
  ),
  data: [
    {
      // img: "https://imgur.com/7pYhyL8.jpg",
      img: "https://framerusercontent.com/images/7NG1Tz0UaTkt0rlbxP9RONj3MM.png",
      name: "Cameron Van",
      profession: "Founder, C.E.O- Couro ",
      country: "USA",
      desc: "We are happy with phase one, I have been constantly reaching out to you guys and thanks for being flexible and willing to work on this. Looking forward to add new things to make the app better for users. We appreciate you guys!",
      problem_solve:
        "Problem Solved: Custom libraries for end customers, admin panel, chat module, custom feed etc.",
      projectimg: (
        <div className="mobile_img_client">
          <img className="mob_img2" src="https://imgur.com/l9Kcm6P.jpg"></img>
          <img className="mob_img1" src="https://imgur.com/WRKKH0q.jpg"></img>
        </div>
      ),
    },
    {
      img: "https://framerusercontent.com/images/AeOQw3L3g1d5PmhCp40Tun70nQU.jpg",
      name: "Rechelle Balanzat",
      profession: "C.E.O, Juliette",
      country: "NY, USA",
      desc: "Manan and his team at modernizing processes are true pleasure to work with. They understood the challenges my business faced and collaborated with me in finding the right solution. They continue to help me push my business forward and I couldn’t recommend a better team for the job, professional, friendly and timely. What else could you ask for?",
      problem_solve:
        "Problem solved: Customer dashboard, analytics, order and payment pages, vendor dashboard, reports module etc.",
      projectimg: (
        <div className="mobile_img_client">
          <img
            className="mob_img2"
            src="https://framerusercontent.com/images/19ggDl1DQBTjyaN3TgpB2vO84E.png"
          ></img>
          <img
            className="mob_img1"
            src="https://framerusercontent.com/images/Si5wV3XhdCwD3kvWaemYYXjPio.png"
          ></img>
        </div>
      ),

      // "src="https://framerusercontent.com/images/19ggDl1DQBTjyaN3TgpB2vO84E.png"",
    },
    {
      img: "https://framerusercontent.com/images/GzmsVI6dGx5XrelGPiNqy1qLE.png",
      name: "Rishu Srivastava",
      profession: "Founder, Tllid",
      country: "",
      desc: "Our app has been doing pretty well, its been 3 months and we have not had users complaining, and its been quite user friendly.",
      problem_solve: "",
      projectimg: (
        <div className="mobile_img_client">
          <img
            className="mob_img2"
            src="https://framerusercontent.com/images/4nhlQvG1covINwqLEyjJvEKLA.png"
          ></img>
          <img
            className="mob_img1"
            src="https://framerusercontent.com/images/3oM8Gia14WaENl6b3GONvOsHg0U.png"
          ></img>
        </div>
      ),
    },
    {
      img: "https://framerusercontent.com/images/R0L9SL0Rcii5daAZJ1raV0lPyCs.png",
      name: "Ron",
      profession: "Managing member, Atlas properties",
      country: "",
      desc: "Manan and his team at Modernizing Processes had been very helpful and diligent in creating the MVP I needed for growing my business!",
      problem_solve:
        "Problem solved: Project management, deal flow, pending RFPs, contracts and payment dashboard.",
      projectimg: (
        <div className="mobile_img_client">
          <img
            className="mob_img2"
            src="https://framerusercontent.com/images/kU5jv0C4aXPjMhulkXkqPqjB724.png"
          ></img>
          <img
            className="mob_img1"
            src="https://framerusercontent.com/images/fWeHR2CrOO0tp0w7OyygILUQREA.png"
          ></img>
        </div>
      ),
    },
    {
      img: "https://framerusercontent.com/images/Lda5tAlHV9iVyAGykTMgS0MDj4.png",
      name: "Tanvir",
      profession: "Co-Founder, Errunds",
      country: "",
      desc: "Its been super awesome to work with them, they used all their knowledge of Glide apps and helped me figure out where we can use glide apps to our advantage!",
      problem_solve: "",
      projectimg: (
        <div className="mobile_img_client">
          <img className="mob_img2" src="https://imgur.com/ctZ3pkN.png"></img>
          <img className="mob_img1" src="https://imgur.com/yaJ654s.png"></img>
        </div>
      ),
    },
  ],
};
const section4 = {
  data: [
    // {
    //   title: (
    //     <div className="section4_utils_title">
    //       See how we helped <span>Jay!</span>
    //     </div>
    //   ),
    //   desc: "See how we helped Jay from everyday bowl eliminate problems like loss of time and lack of efficiency by automating his company’s workflow. We provided Jay with automated scheduling for each subscriber, meal plan inventory for chefs and delivery tracking for drivers.",
    //   btn: "Automate my workflow",
    //   img: "https://imgur.com/bAMn4hd.jpg",
    //   video_link: "https://www.youtube.com/embed/q2imqncBqKk",
    //   imgtitle: "Jay Krishnanunny",
    //   imgdesc: "Everyday Bowl",
    // },
    {
      title: (
        <div className="section4_utils_title">
          See how we helped <span>Ruben!</span>
        </div>
      ),
      // desc: "See how we helped Ruben from Yacht notes eliminate problems like loss of time and lack of efficiency by automating his company’s workflow. We provided Ruben with automated scheduling for each subscriber, meal plan inventory for chefs and delivery tracking for drivers.",
      desc: (
        <>
          From an idea on paper to winning the prestigious Mark Challenge –
          discover how we helped Ruben Mena turn his YACHT NOTES vision into
          reality in just 4 weeks! 🏆🛥️
          <a
            className="linkedinlink"
            href="https://www.linkedin.com/pulse/how-our-app-helped-ruben-win-prestigious-mark-challenge-manan-mehta%3FtrackingId=r%252FzFhYR%252BRdSUYEKbWRgTPg%253D%253D/?trackingId=r%2FzFhYR%2BRdSUYEKbWRgTPg%3D%3D"
            target="_blank"
          >
            Read More...
          </a>
        </>
      ),
      // btn: "Automate my workflow",
      btn: "Craft my MVP",
      img: "https://imgur.com/bAMn4hd.jpg",
      video_link: "https://www.youtube.com/embed/P1IbYbwOHiM",
      imgtitle: "Jay Krishnanunny",
      img_link:
        "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/1691672528734.png",
      imgdesc: "Everyday Bowl",
    },
    {
      title: (
        <div className="section4_utils_title">
          See how we helped <span>Rechelle!</span>
        </div>
      ),
      desc: (
        <>
          How Rechelle Balanzat revolutionized NYC's laundry game. See how our
          partnership with JULIETTE led to doubled orders, 85% client retention,
          and 2000+ hours saved. 🌆📈
          <a
            className="linkedinlink"
            href="https://www.linkedin.com/pulse/how-did-we-help-rechelle-build-innovative-technology-disrupt-mehta-7twmf/"
            target="_blank"
          >
            Read More...
          </a>
        </>
      ),
      // btn: "Automate my workflow",
      btn: "Maximize my efficiency",
      img: "https://imgur.com/bAMn4hd.jpg",
      video_link: "https://www.youtube.com/embed/P1IbYbwOHiM",
      img_link:
        "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/1696006200715.png",

      imgtitle: "Jay Krishnanunny",
      imgdesc: "Everyday Bowl",
    },
    {
      title: (
        <div className="section4_utils_title">
          See how we helped <span>Andrea!</span>
        </div>
      ),
      desc: (
        <>
          From hotel management frustration to tech-driven triumph: Learn how
          Andrea Lasalandra harnessed our expertise to revolutionize his hotel,
          boosting staff performance by 50% and reducing turnover by 60%! 🏨💻
          <a
            className="linkedinlink"
            href="https://www.linkedin.com/pulse/how-did-we-help-andrea-boost-his-hotel-staff-50-launch-manan-mehta%3FtrackingId=WuzPvzRrShGc3D9TZ4R22g%253D%253D/?trackingId=WuzPvzRrShGc3D9TZ4R22g%3D%3D"
            target="_blank"
          >
            Read More...
          </a>
        </>
      ),
      // btn: "Automate my workflow",
      btn: "Revolutionize my idea",
      img: "https://imgur.com/bAMn4hd.jpg",
      video_link: "https://www.youtube.com/embed/P1IbYbwOHiM",
      img_link:
        "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/1692191237762.png",
      imgtitle: "Jay Krishnanunny",
      imgdesc: "Everyday Bowl",
    },
  ],
};

const section5 = {
  title: "Regardless of your growth stage",
  desc: (
    <div>
      "We have the expertise to help you launch your MVP{" "}
      <span>just in 14 days.</span>
    </div>
  ),
  btn: "Launch me now",
};

const section6 = {
  title: "The No-Code wizards",
  items: [
    {
      img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/Manan.png",
      // img: "https://i.imgur.com/vxGyHgZ.png",
      name: "Manan Mehta",
      designation: "Managing Director & Co-Founder Of No-Script",
      txt: `Working aroound a super indulgent and helpful team, shooting videos is always fun!`,
    },
    {
      img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/shantanu.png",
      // img: "https://i.imgur.com/zuv03g3.png",
      name: "Shantanu Iyengar",
      // designation: "Native App Developer And Automation Expert",
      txt: `Native App developer who found a better way to make mobile and web applications`,
    },
    {
      // img: "https://i.imgur.com/gnd3mhi.png",
      img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/sumer.png",
      name: "Sumer Arvind Bhadra",
      // designation: "A Glide App Developer",
      txt: `A glide app developer`,
    },
    {
      // img: "https://i.imgur.com/Y8wrbtE.png",
      img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/Harsh.png",
      name: "Harsh Ganatra",
      // designation: "Project Manager and Client Communication Head",
      txt: `Project manager and client communication head`,
    },
    {
      // img: "https://i.imgur.com/Y8wrbtE.png",
      img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/KD.png",
      name: " Kaustubh Dedhia",
      // designation: "Project Manager and Client Communication Head",
      // txt: `Solving complex logic using Low - Code`,
      txt: `A glide app developer`,
    },
    {
      // img: "https://i.imgur.com/Y8wrbtE.png",
      img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/prathamesh.png",
      name: "Prathamesh Pendharkar",
      // designation: "Project Manager and Client Communication Head",
      // txt: `The opportunities to grow to grow and learn both personally and professionally are incredible.`,
      txt: `Quality Assurance & Jr Business Analyst`,
    },
    {
      // img: "https://i.imgur.com/Y8wrbtE.png",
      img: "https://imgur.com/sNCmwrd.png",
      name: "Harsh Ganatra",
      // designation: "Project Manager and Client Communication Head",
      txt: `Project manager and client communication head`,
    },
    // {
    //   img: "https://i.imgur.com/vuNG2fV.png",
    //   name: "Kaustabh Dedhia",
    //   designation: "Glide Developer",
    //   txt: `Solving complex logix using Low - Code`,
    // },
    // {
    //   img: "https://i.imgur.com/IS32LH0.png",
    //   name: "Prathamesh Pendharkar",
    //   designation: "Quality Assurance & Jr.Business Analyst",
    //   txt: `Is the opportunities to grow and learn both personally and professionally are incredible`,
    // },
    // {
    //   img: "https://i.imgur.com/G2hRxyk.png",
    //   name: "Shraddha Chettiar",
    //   designation: "Business Analyst",
    //   txt: `I get to add more skills and grow myself more as BA with every passing project`,
    //   left: 25,
    // },
    // {
    //   img: "https://i.imgur.com/kLsGpPO.png",
    //   name: "Punitesh Poojary",
    //   designation: "Senior Developer",
    //   txt: `I admire the innovative approach of modernizing processes through customized apps that enhance business control, improve efficiency, and make a real-world impact.`,
    //   left: 25,
    // },
    // {
    //   img: "https://i.imgur.com/95rSJ19.png",
    //   name: "Kathan Mehta",
    //   designation: "EdTech and Ads Copywriter",
    //   txt: `Working around a super indulgent and helpful team, shooting videos is always fun`,
    // },
    // {
    //   img: "https://i.imgur.com/3giXurf.png",
    //   name: "Adarshh Kumar",
    //   designation: "Brand Manager",
    //   txt: `The ceiling to learn here is extremely high`,
    // },
    // {
    //   img: "https://i.imgur.com/1lfPUTR.png",
    //   name: "Raya Mohan",
    //   designation: "Videographer",
    //   txt: `I have flexibility when needed and the camaraderie amongst the team is fun - everyone can speak their mind and are valued and heard`,
    // },
    // {
    //   img: "https://i.imgur.com/29vvciP.png",
    //   name: "Gorande Katira",
    //   designation: "Content Writing",
    //   txt: `The work culture that we have, work doesn't feel like a burden here.`,
    // },
    // {
    //   img: "https://i.imgur.com/L2pXbkT.png",
    //   name: "Dhiraj Ambekar",
    //   designation: "Front-End Developer",
    //   txt: `Working around a super indulgent and helpful team, making websites pages is always fun!`,
    // },
  ],
  btn: "Get expert guidance",
};

const section7 = {
  title: "How will your journey look like",
  item: [
    {
      name: "Discovery call",
      translate: "(Lumos revelation)",
      desc: "A 30 minutes call where we shed light on your needs and aspirations, illuminating your path to digital transformation. We also solidify the spell in the form of a Hermonie- esque contract. ",
      img: image.discovery_call,
    },
    {
      name: "Project architecture",
      translate: "(Accio blueprint)",
      desc: "This is where we design a comprehensive plan, like a magically summoned blueprint, to lay foundation for a successful project. We also showcase a lea MVP to give you a taste of the future.",
      img: image.project_architecture,
    },
    {
      name: "Development",
      translate: "(Incendio codecraft)",
      desc: "This is where the real magic starts, our team of Magicians start creating a powerful spell leveraging the magic of Glide and No-Code. You also get a progress report of how things are progressing",
      img: image.development,
    },
    {
      name: "Testing",
      translate: "(Obliviate analytica)",
      desc: "We use the expertise to cast the enchantus analytica charm on any potential flaws, analyzing everything in pursuit of perfection!",
      img: image.testing,
    },
    {
      name: "Scalability roadmap",
      translate: "(Enchantus expansion)",
      desc: "Before we conclude our journey with you, we map out a scalability future, seamlessly expanding your digital capabilities to empower your business to soar to new dimensions.",
      img: image.scalability_roadmap,
    },
  ],
};
const section8 = {
  title: "App handover ceremony",
  sub_title: "The Expecto Proficientia",
  desc: "This is where we deliver the final product with proficiency, hence evoking the sprit of the Expecto Petronum charm to ward off any future challenges.",
  btn: "Book my call already",
};

const section9 = {
  title: "Modernizing Processes",
  sub: "Is a perfect fit for you if",
  items: [
    {
      title: "Unshakeable Albus",
      desc: "You are ready to forge a startup legacy with unwavering determination!",
      tick: image.tick,
    },
    {
      title: "Lightening Harry",
      desc: "You are looking to scale up quickly and hence don't have months to waste on a native app!",
      tick: image.tick,
    },
    {
      title: "Curious Hermoine",
      desc: "You are willing to be actively involved with developers!",
      tick: image.tick,
    },
    {
      title: "Loyal Hagrid",
      desc: "You are looking for a long term tech partner who will continuously improve your process as you grow!",
      tick: image.tick,
    },
  ],
  btn: "I’m a perfect fit",
};

const section11 = {
  title: "Hoot hoot!",
  sub_title: "Hi there, esteemed witch or wizard! I am Aurelius, Hedwig’s son.",
  sub_title2: "Drop your email below to stay updated with monthly newsletter.",
  desc: "Pssst.... no spam here! We banned it after that ear-splitting howler from Ron’s mother.",
};
export const page_data = {
  section2,
  section3,
  section4,
  section5,
  section6,
  section7,
  section8,
  section9,
  section11,
};
