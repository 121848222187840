import React from "react";
import { Container } from "../components/Container";
import { page_data } from "../utils/utils";
import Button from "../components/Buttons/Button";

const Section8 = () => {
  let { section8 } = page_data;
  return (
    <>
      <div className="section8">
        <Container>
          <div className="inner_section8">
            <div className="section8_title">
              {section8.title}
              <img src="https://imgur.com/LceI1sS.jpg"></img>
            </div>
            <div className="section8_title_sub">{section8.sub_title}</div>
            <div className="section8_desc">{section8.desc}</div>

            <Button custclass="section8_btn" txt={section8?.btn} />
          </div>
        </Container>
      </div>
    </>
  );
};

export default Section8;
