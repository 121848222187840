import React, { useLayoutEffect, useState } from "react";
import { Container } from "../components/Container";
import Button from "../components/Buttons/Button";

const section10 = {
  accordian: [
    {
      que: "How long does it take to build 1 process?",
      ans: "Approximately 3-4 weeks (compared to 3-4 months with code)",
    },
    {
      que: "How do we choose the best stack for development?",
      ans: "Based on our understanding of your process, we will recommend the best tech stack to be used and also outline the exact pros and cons of choosing the stack. Depending on the actual use case, we will choose the tech stack that's most suitable after an in depth discussion with your team.",
    },
    {
      que: "Who owns the app once it's built?",
      ans: "You will be the owner of everything that's built and it will be built on your accounts. We will become collaborators on your project and revoke our own access when the project is handed over.",
    },
    {
      que: "What happens if there are bugs in the system in the future?",
      ans: "Any bugs that arise in the existing scope of work will be fully covered irrespective of the timelines. We prefer to be completely accountable for the product we have built so that it matches the expectations outlined.",
    },
  ],
};

export const Section10 = () => {
  const [current_ques, setcurrent_ques] = useState("");
  const [ques_body_height, setques_body_height] = useState([]);
  useLayoutEffect(() => {
    let temp_height = section10.accordian.map((el, i) => {
      return document
        .getElementById(`ques_accord_body_${i + 1}`)
        .getBoundingClientRect().height;
    });
    setques_body_height(temp_height);
  }, []);
  return (
    <div className="section10">
      <div className="faq_title">
        <div>
          <div>FAQ ' s </div>
          <div>Frequently asked questions</div>
          <img src="https://imgur.com/K82fqLU.png" />
        </div>
      </div>
      <Container>
        {[...section10.accordian].map((el, i) => (
          <div className="accordian_main" key={i}>
            <div
              className="accordian_header"
              onClick={() => {
                setcurrent_ques((prev) => (prev === i + 1 ? "" : i + 1));
              }}
            >
              <div className="que">{el.que}</div>
              <div
                className="accordian_arrow"
                style={{
                  transform: `rotate(${current_ques === i + 1 ? 180 : 0}deg)`,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="34"
                  viewBox="0 0 33 34"
                  fill="none"
                >
                  <path
                    d="M25.4456 11.6307C25.3192 11.4979 25.1688 11.3925 25.003 11.3206C24.8373 11.2487 24.6595 11.2117 24.48 11.2117C24.3005 11.2117 24.1227 11.2487 23.957 11.3206C23.7913 11.3925 23.6408 11.4979 23.5144 11.6307L17.2856 18.1191C17.1592 18.2518 17.0088 18.3572 16.843 18.4291C16.6773 18.5011 16.4995 18.5381 16.32 18.5381C16.1405 18.5381 15.9627 18.5011 15.797 18.4291C15.6313 18.3572 15.4808 18.2518 15.3544 18.1191L9.1256 11.6307C8.99917 11.4979 8.84876 11.3925 8.68303 11.3206C8.5173 11.2487 8.33954 11.2117 8.16 11.2117C7.98047 11.2117 7.80271 11.2487 7.63698 11.3206C7.47125 11.3925 7.32083 11.4979 7.1944 11.6307C6.9411 11.8961 6.79892 12.2552 6.79892 12.6295C6.79892 13.0037 6.9411 13.3628 7.1944 13.6282L13.4368 20.1307C14.2018 20.9266 15.2388 21.3736 16.32 21.3736C17.4012 21.3736 18.4382 20.9266 19.2032 20.1307L25.4456 13.6282C25.6989 13.3628 25.8411 13.0037 25.8411 12.6295C25.8411 12.2552 25.6989 11.8961 25.4456 11.6307Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div
              className="accordian_body"
              style={{
                height: current_ques === i + 1 ? `${ques_body_height[i]}px` : 0,
              }}
            >
              <div id={"ques_accord_body_" + (i + 1)}>{el.ans}</div>
            </div>
          </div>
        ))}
      </Container>
      {/* <Button custclass={"faq_btn"} txt={"Onboard me now"} /> */}
    </div>
  );
};
