import React, { useState } from "react";
import { Container } from "../components/Container";
import { page_data } from "../utils/utils";
import Button from "../components/Buttons/Button";
import { BookACall } from "../components/Buttons/BookACall";
import axios from "axios";

const Section11 = () => {
  let { section11 } = page_data;
  const [input, setInput] = useState({
    email: "",
  });
  const [InputError, setInputError] = useState({
    email: "",
  });
  const [EmailSend, setEmailSend] = useState(false);
  // console.log(input.email);
  return (
    <>
      <div className="section11">
        <Container>
          <div className="inner_section11">
            <div className="inner_section11_title">
              {section11.title}
              <img src="https://imgur.com/b01CTt6.jpg" />
            </div>
            <div className="inner_section11_subtitle">
              <div>
                {section11.sub_title}
                <br className="show_mobile"></br>
                {section11.sub_title2}
              </div>
            </div>
            <div className="email_taker">
              <div className={"cust-input " + (InputError.email && " error")}>
                <input
                  placeholder="Enter your email id"
                  onChange={({ target }) => {
                    setInput((prev) => ({
                      ...prev,
                      email: target.value,
                    }));
                    setInputError((prev) => ({
                      ...prev,
                      email:
                        // target.value === "" ||
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                          target.value
                        ),
                    }));
                  }}
                ></input>
              </div>
              {/* <Button txt={section8?.btn}/> */}
              <BookACall
                txt={"Send"}
                {...{
                  click: () => {
                    
                    let base_url =
                      "https://script.google.com/macros/s/AKfycbwnkESLeZt76BOvO-srRdRJKCHlQMzv9e8wvr-mGcbAaivOvwx4BmtkOsCK1WP2KYXkGg/exec";
                    if (
                      input.email === "" ||
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                        input.email
                      )
                    ) {
                      return setInputError((prev) => ({
                        ...prev,
                        email:
                          input.email === "" ||
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                            input.email
                          ),
                      }));
                    }
                    setEmailSend(true);
                    try {
                      axios.get(`${base_url}?${input.email}:::${"2"}`);
                    } catch (err) {}
                    return;
                  },
                }}
              />
            </div>
            {/* {EmailSend ? ( */}

            {EmailSend ? (
              <div className="inner_section11_thank_you">
                <div className="thank">Thank you.
                We will get back to you soon</div>
              </div>
            ) : (
              ""
            )}
            <div className="section11_desc">{section11.desc}</div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Section11;
