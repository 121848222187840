import React from "react";
import { image } from "../../utils/images";

const Button = ({ custclass, txt, click }) => {
  return (
    <div
      className={"cust-btn " + (custclass ? ` ${custclass}` : "")}
      onClick={() => {
        window.open("https://calendly.com/manan_mehta");
      }}
    >
      {txt}
      <div className="show_mobile">{image.double_right}</div>
    </div>
  );
};

export default Button;
