import React, { useRef, useState } from "react";
import { Container } from "../components/Container";
import { page_data } from "../utils/utils";
import Slider from "react-slick";

const Section7 = () => {
  let { section7 } = page_data;
  const slideTo9 = useRef();
  const [state, setState] = useState(0);
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 10000,
    swipeToSlide: true,
    arrows: false,
    // cssEase: ""
    afterChange: (a) => setState(a),
  };
  return (
    <>
      <div className="section7" id="how-it-works">
        <Container>
          <div className="inner_section7">
            <div className="section7_title">{section7.title}</div>
            <div className="section7_items">
              {section7.item.map((el, i) => (
                <div key={i} className="items">
                  <div className="item_image">{el.img}</div>
                  <div className="items_name">{el.name}</div>
                  <div className="item_translate">{el.translate}</div>
                  <div className="item_desc">{el.desc}</div>
                </div>
              ))}
            </div>
          </div>
        </Container>

        <div className="inner_section7_mobile">
          <div className="section7_title_mobile">
          Your journey
          </div>
          <Slider ref={slideTo9} {...settings}>
            {section7.item.map((el, i) => (
              <Container key={i}>
                <div  className="items_mob">
                  <div className="item_mobile_header">
                    <div className={`item_image_mob_${i +1}`}>{el.img}</div>
                    <div className="item_mobile_name_translate">
                      <div className="items_name_mob">{el.name}</div>
                      <div className="item_translate_mob">{el.translate}</div>
                    </div>
                  </div>
                  <div className="item_desc_mob">{el.desc}</div>
                </div>
              </Container>
            ))}
          </Slider>
          <div className="dots_collection">
            {section7.item.map((el, i) => (
              <div
                key={i}
                className={"dots " + (state === i && " activedots")}
                onClick={() => {
                  setState(i);
                  slideTo9.current.slickGoTo(i);
                }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Section7;
