import React from "react";
import { image } from "../utils/images";
import { Container } from "./Container";

export const Footer = () => {
  return (
    <div className="footer">
      <Container cust_class="footer_main">
        <div className="footer1">{image.logo}</div>
        <div className="footer2">
          <div className="title">Contact info</div>
          <div className="contact contact1">
            <div className="svg_holder">{image.mail}</div>
            <a href="mailto:manan@modernizingprocess.com">
              manan@modernizingprocess.com
            </a>
          </div>
          <div className="contact contact2">
            <div className="svg_holder">{image.phone}</div>
            <a href="tel:+91 9820026270">+91 98200 26270</a>
          </div>
          <div className="contact contact3">
            <div className="svg_holder">{image.location}</div>
            <a href="https://goo.gl/maps/7Zn7NtNnTADzDPL3A" target="_blank">
              Filix Tower, 407, Lal Bahadur Shastri rd, Bhandup west, Mumbai,
              Maharashtra, 400078.
            </a>
          </div>
        </div>
        <div className="footer3">
          <div className="days">
            <div className="svg_holder">{image.calender}</div>
            <div>Monday - Friday</div>
          </div>
          <div className="days">
            <div className="svg_holder">{image.clock}</div>
            <div>10AM - 8 PM</div>
          </div>
          {/* <div className="find_txt">Find us on</div>
          <div className="contactus">
            {image.facebook}
            {image.instagram}
            {image.twitter}
            {image.linkedin}
          </div> */}
        </div>
      </Container>
      <Container>
        <div className="rights">
          @2023. Modernizing Processes. All Rights Reserved
        </div>
      </Container>
    </div>
  );
};
