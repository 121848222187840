import React, { useLayoutEffect, useState } from "react";
import { Container } from "../components/Container";
import { image } from "../utils/images";
import { page_data } from "../utils/utils";
import Slider from "react-slick";
import Button from "../components/Buttons/Button";

var setting = {
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 3000,
  autoplaySpeed: 2000,
  centerPadding: "60px",
  dots: false,
  arrows: false,
};
var settingmob = {
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  speed: 1000,
  autoplaySpeed: 2000,
  centerPadding: "0 ",
  dots: false,
  arrows: false,
  className: "team_mobile_slick",
  // className: "center",
  // centerMode: true,
};

const Section6 = () => {
  let { section6 } = page_data;
  const [height, setheight] = useState([]);
  // useLayoutEffect(() => {
  //   setInterval(() => {
  //     setheight(
  //       [...section6.items].map((el, i) => {
  //         return (
  //           document
  //             .getElementById("our_team_description" + (i + 1))
  //             .getBoundingClientRect().height - 5
  //         );
  //         // console.log(document.getElementById("our_team_item" + (i + 1)));
  //       })
  //     );
  //   }, 3000);
  // }, []);
  return (
    <>
      <div className="section6" id="our-team">
        <Container>
          <div className="inner_section6">
            <div className="section6_moto">
              {image.trophy}
              <div className="section6_moto_title">
                {/* {section6.title} */}
                Our order of phoenix
                {/* <div className="section6_moto_inner_title">
                  India’s finest glide app developers
                </div> */}
              </div>
            </div>
            {/* {window.innerWidth <= 900 ? (
              <div className="team_mobile">
                <Slider {...settingmob}>
                  {section6.items.map((el, i) => (
                    <div className="team_item_main_mobile" key={i}>
                      <div
                        className="img"
                        style={{
                          backgroundImage: `url(${el.img})`,
                        }}
                      />

                      <div
                        className="item_description"
                        style={{
                          "--desc_height": 0,
                          "--bottom_desc": height[i] || 0,
                        }}
                        id={"our_team_item" + (i + 1)}
                      >
                        <div className="name_mob">{el.name}</div>
                        <div className="designation_mob">{el.designation}</div>
                        <div
                          className="description_mob"
                          id={"our_team_description" + (i + 1)}
                        >
                          {el.txt}
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <Slider {...setting}>
                {section6.items.map((el, i) => (
                  <div className="team_item_main" key={i}>
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url(${el.img})`,
                        //   ...(el?.left
                        //     ? { backgroundPositionX: `${el.left}%` }
                        //     : {}),
                      }}
                    />

                    <div
                      className="item_description"
                      style={{
                        "--desc_height": 0,
                        "--bottom_desc": height[i] || 0,
                      }}
                      id={"our_team_item" + (i + 1)}
                    >
                      <div className="name">{el.name}</div>
                      <div className="designation">{el.designation}</div>
                      <div
                        className="description"
                        id={"our_team_description" + (i + 1)}
                      >
                        {el.txt}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
            {} */}
            <img
              className="img_border_rad"
              style={{ maxWidth: "100%", margin: "5vw  0 0 0" }}
              // src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/OurTeam.jpg"
              src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/OurTeam.JPG"
            />
            <div className="section6_desc ">
              {image.bulg}
              <div>
                We are the only No-code agency in India with
                <div>
                  <span
                    style={{
                      color: "var(--base_primary)",
                    }}
                  >
                    7
                  </span>{" "}
                  proud glide experts.
                </div>
              </div>
            </div>
            <div className="section6_desc_mobile">
              <div className="section6_desc1">
                {image.bulg}
                Did you know?
              </div>
              <div className="section6_desc2">
                We are the only No-code agency in India with{" "}
                <span
                  style={{
                    color: "var(--base_primary)",
                  }}
                >
                  7
                </span>{" "}
                proud glide experts.
              </div>
            </div>
            <Button custclass="section6_btn" txt={section6.btn} />
          </div>
        </Container>
      </div>
    </>
  );
};

export default Section6;
