import React from "react";
import { Container } from "../components/Container";
import { image } from "../utils/images";
import { BookACall } from "../components/Buttons/BookACall";
import img_placeholder1 from "../utils/Images/Project-Baarah-Promo.jpg";
import Video from "../utils/Video/Video";
export const Section1 = () => {
  return (
    <div className="section1">
      <svg
        className="section_bg_red"
        xmlns="http://www.w3.org/2000/svg"
        width="723"
        height="618"
        viewBox="0 0 723 618"
        fill="none"
      >
        <path
          d="M391.23 0L723 0V618H0L391.23 0Z"
          fill="url(#paint0_linear_1_349)"
          fillOpacity="0.25"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_349"
            x1="361.5"
            y1="-2.47631e-05"
            x2="164.261"
            y2="730.756"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B50000" />
            <stop offset="1" stopColor="#B50000" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <Container cust_class="section1">
        <div className="left">
          <div className="txt1">
            From idea to MVP in record time:{" "}
            <span className="txt2_mobile">14 days</span>
          </div>
          <div className="txt2">14 days</div>
          <div className="txt3">
            You heard it right, leveraging the magic of No-Code we can have a
            lean MVP ready within 14 days.
          </div>
          <BookACall
            {...{
              click: () => {
                window.open("https://calendly.com/manan_mehta");
              },
            }}
          />
        </div>
        <div className="right">
          {/* <Video
            data="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Baarah/MP%20Promo%20Video.mp4"
            img_placeholder={img_placeholder1}
          /> */}
          <div className="cust-video-iframe">
            <iframe
              // width="100%"
              // height="315"
              // src="https://www.youtube.com/embed/_E7-8h22wEM"
              // src="https://youtu.be/YrXtR32F90s"
              src="https://www.youtube.com/embed/YrXtR32F90s?si=tkFzPJb44bF65NIC"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          {image.wand}
        </div>
        <BookACall
          custClass="bookcall_mobile"
          {...{
            click: () => {
              window.open("https://calendly.com/manan_mehta");
            },
          }}
        />
      </Container>
    </div>
  );
};
