import React, { useRef } from "react";
import { page_data } from "../utils/utils";
import Button from "../components/Buttons/Button";
import { Container } from "../components/Container";
import Slider from "react-slick";
import { image } from "../utils/images";
var Brand = {
  infinite: true,
  slidesToShow: 7,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 3000,
  //   className: "center",
  //   centerMode: true,
  centerPadding: "60px",
  dots: false,
  arrows: false,
  swipeToSlide: true,
};
var BrandMobile = {
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 3,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 3000,
  //   className: "center",
  //   centerMode: true,
  centerPadding: "60px",
  dots: false,
  arrows: false,
  swipeToSlide: true,
};
const Section2 = () => {
  let { section2 } = page_data;
  //   const slidehomeTitle = useRef();
  return (
    <>
      <div className="section2" id="our-clients">
        <Container>
          <div className="section2_title">
            {section2?.title}
            <div className="sup">
              {image.snitch}
              {/* <img src="https://imgur.com/1tRu5AE.jpg"></img> */}
            </div>
            <div className="mobile_flying_ball">
              {image.snitch}
            </div>
              {/* <img className="mobile_flying_ball" src="https://imgur.com/1tRu5AE.jpg"></img> */}
          </div>
          <div className="golden_snitches">
            <div className="experience_detail">
              <div className="exp_year">{section2?.exp?.count}</div>
              {section2?.exp?.desc}
            </div>
            <div className="golden_right">
              {section2.data.map((el, i) => (
                <div key={i} className="global_right_box">
                  <div className="global_box_count">{el.count}</div>
                  <div>{el.desc}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="brand_collection">
            {window.innerWidth <= 700 ? (
              <Slider {...BrandMobile}>
                {section2?.brand?.map((el, i) => (
                  <div key={i}>
                    <img src={el?.img}></img>
                  </div>
                ))}
              </Slider>
            ) : (
              <Slider {...Brand}>
                {section2?.brand?.map((el, i) => (
                  <div key={i}>
                    <img src={el?.img}></img>
                  </div>
                ))}
              </Slider>
            )}
          </div>
          <Button custclass="section2_btn" txt={section2?.btn} />
        </Container>
      </div>
    </>
  );
};

export default Section2;
